/* //////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////// FONT /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ---------------------------------------------------------------- FUTURA BOOK --- */
@font-face {
    text-rendering: optimizeLegibility;
    font-family: 'futura';
    font-weight: normal;
    font-style: normal;
    src: url('../font/futura_book.eot');
    src: url('../font/futura_book.eot?#iefix') format('embedded-opentype'),
    url('../font/futura_book.woff') format('woff'),
    url('../font/futura_book.ttf') format('truetype'),
    url('../font/futura_book.svg#futura_book') format('svg');
}

/* ---------------------------------------------------------------- FUTURA BOLD --- */
@font-face {
    text-rendering: optimizeLegibility;
    font-family: 'futura';
    font-weight: bold;
    font-style: normal;
    src: url('../font/futura_bold.eot');
    src: url('../font/futura_bold.eot?#iefix') format('embedded-opentype'),
    url('../font/futura_bold.woff') format('woff'),
    url('../font/futura_bold.ttf') format('truetype'),
    url('../font/futura_bold.svg#futura_bold') format('svg');
}

/* ------------------------------------------------------ FUTURA CONDENSED BOLD --- */
@font-face {
    text-rendering: optimizeLegibility;
    font-family: 'futura_condensed';
    font-weight: bold;
    font-style: normal;
    src: url('../font/futura_condensed_bold.eot');
    src: url('../font/futura_condensed_bold.eot?#iefix') format('embedded-opentype'),
    url('../font/futura_condensed_bold.woff') format('woff'),
    url('../font/futura_condensed_bold.ttf') format('truetype'),
    url('../font/futura_condensed_bold.svg#futura_condensed_bold') format('svg');
}
