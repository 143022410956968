/* //////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// GLOBAL /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ---------------------------------------------------------------------- RESET --- */
@import 'reset';

/* ----------------------------------------------------------------------- FONT --- */
@import 'font';

/* ---------------------------------------------------------------------- COLOR --- */
$grey6: #656565;
$blue9: #0f0744;


/* //////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////// TYPOGRAPHY /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ----------------------------------------------------------------------- ROOT --- */
:root {
    font-size: 16px;
}

/* ------------------------------------------------------------------- DOCUMENT --- */
html,
body {
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* ----------------------------------------------------------------------- BODY --- */
body {
    font-family: 'futura';
    font-weight: 400;
    color: $grey6;
}


/* //////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////// STRUCTURE /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ----------------------------------------------------------------------- BODY --- */
body {
    background-color: $blue9;
}

/* ----------------------------------------------------------------------- MAIN --- */
main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

/* ----------------------------------------------------------------------- LOGO --- */
.logo {
    width: 176px;
    height: 66px;
    margin: 0 auto 32px;
    background-image: url(../gfx/logo.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

/* ----------------------------------------------------------------------- TEXT --- */
.text {
    text-transform: uppercase;
    font-family: 'futura_condensed';
    font-size: 1.5rem;
    color: white;
}
